.app-link {
    p {
        margin-bottom: 30px;
    }

    .btn {
        min-width: 150px;
    }
}

.user-info {
    margin: 15px 0px;

    span {
        margin-bottom: 5px;
        display      : inline-block;
    }
}