.bg-btc {
    background: #F7931A;
}


.bg-eth {
    background: #282828;
}

.bg-usdt {
    background: #2CA07A;
}

.bg-xrp {
    background: #346AA9;
}

.bg-ltc {
    background: #838383;
}

.bg-ada {
    background: #3CC8C8;
}

.bg-eos {
    background: #19191A;
}

.bg-xmr {
    background: #FF6600;
}

.bg-xtz {
    background: #A6DF00;
}

.bg-xem {
    background: #41bf76;
}

.bg-neo {
    background: #58BF00;
}

.bg-dash {
    background: #1c75bc;
}

.bg-etc {
    background: #669073;
}

.bg-dgb {
    background: #0066cc;
}

.bg-dcr {
    background: #3b7cfb;
}

.bg-rep {
    background: #40a2cb;
}



.bg-gradient-primary {
    background: linear-gradient(50deg, #6e00ff 0, #bb00ff 100%) !important;
}


// Color 
.bg-primary {
    background-color: #2083fe !important
}

.bg-secondary {
    background-color: #5a6c90 !important
}

.bg-success {
    background-color: #34bd91 !important
}

.bg-info {
    background-color: #18b7ff !important
}

.bg-warning {
    background-color: #fdc724 !important
}

.bg-danger {
    background-color: #bd4242 !important
}

.bg-light {
    background-color: #f6f9fc !important
}

.bg-dark {
    background-color: #1e2e50 !important
}

.bg-purple {
    background-color: #6672e8 !important
}

.bg-sky {
    background-color: #e9f4ff !important
}

.bg-pistachio {
    background-color: #ddf5f4 !important
}

.bg-darkblue {
    background-color: #1f2c73 !important
}

.bg-body {
    background-color: #fff !important
}

.bg-white {
    background-color: #fff !important
}

.bg-transparent {
    background-color: transparent !important
}
