.perfection-content {
    padding   : 50px 30px;
    background: #fff;
    margin    : 25px 0px;
    min-height: 200px;

    p {
        margin-bottom: 0px;
    }
}


.advance-trading-content {
    padding      : 50px 30px;
    background   : #fff;
    // margin    : 25px 0px;
    // min-height: 200px;

    p {
        margin-bottom: 0px;
    }
}

.advance-trading-img {
    img {
        box-shadow: $shadow;
    }
}


.get-started-content {
    padding   : 30px 30px;
    background: #fff;
    margin    : 25px 0px;
    min-height: 200px;

    h4 {
        margin-bottom: 20px;
    }

    p {
        margin-bottom: 0px;
    }
}