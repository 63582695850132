.intro-content {
    h1 {
        // font-weight: 400;
        line-height       : 55px;
        margin-bottom     : 30px;
        // strong {
        //     font-weight: 500;
        // }
    }

    p {
        font-size    : 20px;
        margin-bottom: 50px;
    }
}

.shape {
    position  : absolute;
    bottom    : 0;
    left      : 0;
    right     : 0;
    background: url(./../images/svg/shape.svg);
    height    : 160px;
    z-index   : -1;
}


.bg-primary {
    .intro-btn {
        .btn-primary {
            background: $white;
            color     : $primary;
            border    : 1px solid darken($primary, 10%);
        }

        .btn-outline-primary {
            color : $white;
            border: 1px solid $white;
        }
    }
}




// .intro-btn {
//     @include respond('tab-port') {
//         margin-bottom: 50px;
//     }

//     .btn-primary {
//         padding     : 10px 30px;
//         margin-right: 30px;
//         font-weight : 600;
//         font-size   : 16px;
//     }

//     .btn-outline-primary {
//         padding    : 10px 30px;
//         font-weight: 600;
//         font-size  : 16px;
//     }
// }

.intro-form-exchange {
    padding      : 30px;
    box-shadow   : $shadow;
    border-radius: 5px;
    background   : #fff;
    z-index      : 1;
    margin       : 20px 0px;

    .nice-select {
        border-top-left-radius   : 5px !important;
        border-bottom-left-radius: 5px !important;
    }

    .btn {
        padding    : 10px 15px;
        font-weight: 600;
        position   : relative;
        font-size  : 16px;

        i {
            font-size  : 22px;
            font-weight: bold;
            position   : absolute;
            right      : 15px;
        }
    }
}

.shape-container {
    position : absolute;
    left     : 0;
    right    : 0;
    width    : 100%;
    // height: 150px;
    z-index  : 2;
    transform: translateZ(0);
    overflow : hidden;
}

.shape-container[data-shape-position="bottom"] {
    top          : auto;
    bottom       : 0;
    margin-bottom: -6px;
}

.shape-container svg {
    fill          : #FFF;
    pointer-events: none;
    vertical-align: baseline;
}