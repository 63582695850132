// Google Font
@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@400;600;700&display=swap');
// // Line Awesome
@import url("../icons/font-awesome/css/all.css");
@import url('../icons/icofont/icofont.min.css');
@import url('../icons/bootstrap-icons/bootstrap-icons.css');
@import url("../icons/line-awesome/css/line-awesome.min.css");
@import url('../icons/cryptocoins/css/cryptocoins.css');
@import url('../icons/cryptocoins/css/cryptocoins-colors.css');









