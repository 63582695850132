// @import "../vendor/animate/animate.min.css";
// @import "../vendor/waves/waves.min.css";
@import "../vendor/perfect-scrollbar/perfect-scrollbar.css";
@import "../vendor/basic-table/basictable.css";

* {
    outline: none;
    padding: 0;

    &::after {
        margin : 0;
        padding: 0;
    }

    &::before {
        margin : 0;
        padding: 0;
    }
}


body {
    &.dashboard {
        background: $light;
        font-size : 14px;
    }
}


#main-wrapper {
    opacity   : 0;
    transition: all 0.25s ease-in;
    overflow  : hidden;
    position  : relative;
    z-index   : 1;
    // height: 100vh;
    margin-top: 80px;

    // &::before {
    //     content   : "";
    //     height    : 300px;
    //     top       : 0;
    //     left      : 0;
    //     width     : 100%;
    //     position  : absolute;
    //     z-index   : -9;
    //     background: $primary;
    // }

    &.show {
        opacity: 1;
    }
}

.content-body {
    margin-left        : 100px;
    // padding-top     : 100px;
    margin-top         : 15px;
    // margin-bottom: 100px;

    @include respond('phone-land') {
        margin-left  : 0px;
        margin-bottom: 50px;
    }
}

ul {
    padding: 0;
    margin : 0;
}

li {
    list-style: none;
}

a {
    color          : $primary;
    text-decoration: none;
    outline        : none;

    &:hover,
    &:focus,
    &.active {
        text-decoration: none;
        outline        : none;
        color          : $primary;
    }
}

b,
strong {
    color: $dark;
}



/* Editable */

@include media-breakpoint-up(xxl) {
    .container-fluid {
        max-width: 80%;
    }
}