.market-table {
    table {
        thead {
            th {
                // border: 0px;
                font-size: 14px;
            }
        }

        tbody {

            td,
            th {
                border-color: $border-color;
                background  : lighten($primary, 46%);
            }

            .coin_icon {
                display    : flex;
                align-items: center;

                i {
                    font-size   : 30px;
                    margin-right: 10px;
                    line-height : 0;
                    top         : 3px;
                    position    : relative;
                }

                span {
                    b {
                        font-weight: 400;
                        margin-left: 20px;
                    }
                }
            }

            .btn {
                font-size: 14px;
                padding  : 5px;
                min-width: 59px;
            }
        }
    }
}

.info-content {
    background   : $light;
    text-align   : center;
    padding      : 50px 20px 70px !important;
    margin-bottom: 30px;
    border-radius: $radius;

    // max-width: 180px;
    i {
        font-size: 36px;
        color    : $primary;
    }

    h4 {
        margin-top   : 15px;
        font-size    : 17px;
        margin-bottom: 0px;
    }
}


.product-feature-text {
    margin-bottom: 50px;

    h4 {
        font-size: 20px;

        span {
            margin-right: 10px;
            color       : $purple;
            font-size   : 30px;
        }
    }
}

.product-feature-box {
    background   : $white;
    padding      : 30px 15px;
    text-align   : center;
    margin-bottom: 30px;
    border-radius: 5px;
    // box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.1);
    border       : 1px solid $border-color;

    span {
        padding      : 10px 15px;
        background   : $primary;
        color        : $white;
        display      : inline-block;
        border-radius: 5px;
        margin-top   : 5px;
        font-size    : 24px;
    }

    h4 {
        margin: 20px 0 15px;
    }

    p {
        padding: 0px 15px;
        margin : 0px;
    }
}

// New Product

.new-product-content {
    box-shadow: 0px 20px 25px rgba(22, 28, 45, 0.05);
    padding   : 30px 30px 50px;
    text-align: center;
    background: $white;

    img {
        max-width    : 100px;
        margin-bottom: 30px;
    }

    h4 {
        font-weight  : 600;
        font-size    : 20px;
        margin-bottom: 15px;
    }

    p {
        min-height: 110px;

        @include respond("tab-port") {
            min-height: 200px;
        }

        @include respond("phone-land") {
            min-height: auto;
        }
    }
}

// trust

// .trust {
//     border-top: 1px solid $border-color;
// }

.trust-content {
    margin       : 25px 0;
    text-align   : center;
    box-shadow   : 0 1.5rem 4rem rgba(22, 28, 45, 0.05);
    padding      : 30px;
    min-height   : 250px;
    border-bottom: 5px solid $purple;
    border-radius: 5px;

    span {
        margin-top: 20px;
        display   : inline-block;

        i {
            color    : $purple;
            font-size: 36px;
        }
    }

    h4 {
        font-size    : 20px;
        margin-top   : 30px;
        margin-bottom: 15px;
        text-overflow: ellipsis;
        overflow     : hidden;
        white-space  : nowrap;
    }

    p {
        line-height: 27px;
    }
}

.cookie_alert {
    .alert {
        margin-bottom: 0px;
        position     : fixed;
        max-width    : 385px;
        left         : 15px;
        right        : 0;
        bottom       : 15px;
        z-index      : 9999;
        box-shadow   : 0 1.5rem 4rem rgba(47, 44, 216, 0.25);
        padding      : 20px 30px;
    }

    .la-close {
        position: absolute;
        right   : 12px;
        top     : 12px;
        cursor  : pointer;
    }

    p {
        font-weight: 400;
        color      : $headings-color;
        line-height: 26px;

        a {
            color: $primary;
        }
    }

    .btn {
        padding: 7px;
    }
}

.features-content {
    text-align: center;

    @include respond("phone-land") {
        margin-bottom: 50px;
    }

    span {
        i {
            color    : $primary;
            font-size: 36px;
        }
    }

    h4 {
        font-size    : 20px;
        margin-top   : 30px;
        margin-bottom: 15px;
        text-overflow: ellipsis;
        overflow     : hidden;
        white-space  : nowrap;
    }

    p {
        line-height: 27px;
        max-width  : 350px;
        margin     : 0 auto 30px;
    }

    a {
        color    : $primary;
        font-size: 14px;
    }
}


.customer-support-content {
    text-align: left;
    margin    : 50px 0px;
    background: $white;
    padding   : 50px 30px;
    min-height: 300px;



    span {
        i {
            color    : $primary;
            font-size: 36px;
        }
    }

    h4 {
        font-size    : 20px;
        margin-top   : 30px;
        margin-bottom: 15px;
        text-overflow: ellipsis;
        overflow     : hidden;
        white-space  : nowrap;
    }

    p {
        line-height   : 27px;
        // max-width  : 350px;
        // margin     : 0 auto 30px;
    }

    a {
        color    : $primary;
        font-size: 14px;
    }
}



// .join-team {
//     border-bottom: 1px solid $border-color;
// }





.intro-video-play {
    background-color   : $white;
    background-image   : url('./../images/background/2.jpg');
    background-size    : cover;
    background-position: 0 0;
    min-height         : 465px;
    // max-width: 800px;
    margin             : 0 auto;
    position           : relative;
    border-radius      : 5px;
    z-index            : 1;
    margin-bottom      : 30px;
    box-shadow         : 0 1.5rem 4rem rgba(22, 28, 45, 0.1);

    &::after {
        content      : '';
        position     : absolute;
        top          : 0;
        left         : 0;
        right        : 0;
        bottom       : 0;
        background   : $primary;
        opacity      : 0.45;
        border-radius: 5px;
        z-index      : -1;
    }
}

.play-btn {
    position       : absolute;
    display        : flex;
    align-items    : center;
    justify-content: center;
    height         : 100%;
    width          : 100%;

    a {
        background   : $white;
        width        : 75px;
        height       : 75px;
        border-radius: 100px;
        padding      : 27px 0;
        text-align   : center;
        cursor       : pointer;

        i {
            color: $primary;
        }
    }
}